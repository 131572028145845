import React from "react";
import "./App.css";
import {Header, Footer, ProductModal} from "./Components";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {Box} from "@chakra-ui/react";
import {Home, Request, Vendor, PageNotFound, About} from "./Pages";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-PWNN646",
};

TagManager.initialize(tagManagerArgs);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/request",
    element: <Request />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/vendor/:vendor",
    element: <Vendor />,
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
]);

function App() {
  return (
    <>
      <Header />
      <Box maxH={"calc(100vh - 130px)"} overflow={"auto"} margin={"0 0 2rem 0"}>
        <RouterProvider router={router} />
      </Box>
      <ProductModal />
      <Footer />
    </>
  );
}

export default App;
