import React, {useState, useEffect, useRef} from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Td,
  TableContainer,
  Box,
  Text,
  Select,
  Button,
  Link,
} from "@chakra-ui/react";
import {useCalculator} from "../context/PocketCalculator";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import {CSVLink} from "react-csv";
import html2canvas from "html2canvas";

const EstimateTable = () => {
  const {
    selectedPlan,
    SelectedData,
    billingCycle,
    virtualMachinePlan,
    selectedOS,
    requestId,
  } = useCalculator();
  const [totalCPU, setTotalCPU] = useState(0);
  const [totalRAM, setTotalRAM] = useState(0);
  const [, setTotalCost] = useState(-1);
  const [selectedOption, setSelectedOption] = useState("Export");
  const csvLinkRef = useRef();

  useEffect(() => {
    setTotalCPU(
      SelectedData.reduce((acc, curr) => {
        return acc + curr.quantity * curr.cpu;
      }, 0)
    );
    let totalRAM = SelectedData.reduce((acc, curr) => acc + curr?.quantity * curr.ram, 0);
    setTotalRAM(totalRAM);

    let val = -1;
    totalRAM = totalRAM / 1024;

    virtualMachinePlan.forEach((item) => {
      if (item.value?.memory >= totalRAM && val === -1 && item.label !== "CUSTOM") {
        val = item?.value?.price?.[billingCycle?.label];
      }
    });

    setTotalCost(val);
  }, [SelectedData, virtualMachinePlan, billingCycle?.label]);

  const handleSelectChange = (event) => {
    let val = event.target.value;
    setSelectedOption(val);

    if (val === "JSON") downloadJSON();
    else if (val === "PDF") generatePDF();
    else if (val === "CSV") csvLinkRef.current.link.click();
  };

  const generatePDF = async () => {
    let data = SelectedData.map((item, idx) => {
      return {
        Id: ++idx,
        Name: item.name,
        CPU: item.cpu,
        Ram: item.ram >= 1024 ? item.ram / 1024 + " GB" : item.ram + " MB",
        Vendor: item.vendor,
        Quantity: item.quantity,
        "Total CPU": item.quantity * item.cpu,
        "Total RAM":
          item.ram * item.quantity >= 1024
            ? (item.ram * item.quantity) / 1024 + " GB"
            : item.ram * item.quantity + " MB",
      };
    });

    const doc = new jsPDF();

    const headers = Object.keys(data[0]);
    const body = data.map((obj) => Object.values(obj));
    doc.setPage(1);

    // html content 1 of estimate image
    let htmlContent = document.getElementById("company-info");
    let canvas = await html2canvas(htmlContent);
    let imgData = canvas.toDataURL("image/png");
    doc.addImage(imgData, "PNG", 15, 5, 150, 60);

    // html content 2
    htmlContent = document.getElementById("summary");
    canvas = await html2canvas(htmlContent);
    imgData = canvas.toDataURL("image/png");
    doc.addImage(imgData, "PNG", 15, 70, 40, 27);

    autoTable(doc, {
      head: [headers],
      body: body,
      startY: 100,
      headStyles: {
        fillColor: [0, 0, 0],
        textColor: [255, 255, 255],
      },
    });

    doc.save("cml-estimate.pdf");
  };

  const downloadJSON = () => {
    let data = SelectedData.map((item, idx) => {
      return {
        Id: ++idx,
        Name: item.name,
        CPU: item.cpu,
        Ram: item.ram >= 1024 ? item.ram / 1024 + " GB" : item.ram + " MB",
        Vendor: item.vendor,
        Quantity: item.quantity,
        "Total CPU": item.quantity * item.cpu,
        "Total RAM":
          item.ram * item.quantity >= 1024
            ? (item.ram * item.quantity) / 1024 + " GB"
            : item.ram * item.quantity + " MB",
      };
    });

    const jsonData = JSON.stringify(data, null, 2);
    const blob = new Blob([jsonData], {type: "application/json"});
    const a = document.createElement("a");

    a.href = URL.createObjectURL(blob);
    a.download = "cml-estimate.json";

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  if (SelectedData.length === 0)
    return (
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        minH={"100px"}
        border={"1px dashed #6b7280"}
        color={"#6b7280"}
        margin={"1rem 0 0 0"}
        data-cy="empty-state-message"
      >
        Please Select Some Vendor
      </Box>
    );
  else
    return (
      <>
        <Box
          width={"100%"}
          margin={"0 0 1rem 0"}
          fontSize={"sm"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"flex-start"}
        >
          <Box margin={"0 1.75rem 0 0"} id="summary">
            <Box display={"flex"} gap={2} data-cy="estimate-date">
              <Text color={"#111b27"} fontWeight={"500"}>
                Estimate Date:
              </Text>
              <Text>
                {new Date().toLocaleDateString("en-US", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                })}
              </Text>
            </Box>
            <Box display={"flex"} gap={2}>
              <Text color={"#111b27"} fontWeight={"500"} data-cy="service-item">
                Service Item:
              </Text>
              <Text>12 Months</Text>
            </Box>
            <Box display={"flex"} gap={2}>
              <Text color={"#111b27"} fontWeight={"500"} data-cy="total-cpu">
                Total CPU:
              </Text>
              <Text>{totalCPU}</Text>
            </Box>
            <Box display={"flex"} gap={2} data-cy="total-ram">
              <Text color={"#111b27"} fontWeight={"500"}>
                Total RAM:
              </Text>
              <Text>{totalRAM >= 1024 ? totalRAM / 1024 + " GB" : totalRAM + " MB"}</Text>
            </Box>
            <Box display={"flex"} gap={2} data-cy="request-id">
              <Text color={"#111b27"} fontWeight={"500"}>
                Request Id:
              </Text>
              <Text>{requestId}</Text>
            </Box>
            {selectedPlan?.label !== "CUSTOM" && (
              <>
                {selectedPlan?.value?.price?.[billingCycle?.label?.toLowerCase()] +
                selectedOS?.price?.[billingCycle?.label?.toLowerCase()] ? (
                  <Box display={"flex"} gap={2}>
                    <Text color={"#111b27"} fontSize={"14px"} fontWeight={"500"}>
                      Total Cost:
                    </Text>
                    <Text>
                      $
                      {selectedPlan?.value?.price?.[billingCycle?.label?.toLowerCase()] +
                        selectedOS?.price?.[billingCycle?.label?.toLowerCase()]}
                      &nbsp;USD
                    </Text>
                  </Box>
                ) : (
                  ""
                )}
              </>
            )}
            <Box display={"flex"} gap={2}>
              <Text>&nbsp;</Text>
            </Box>
          </Box>
        </Box>
        <Box
          width={"100%"}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          margin={"-1.5rem 0 0 0"}
        >
          <Box />
          <Box display={"flex"} gap={2}>
            <Link href="/vendor/all-vendor">
              <button
                style={{
                  padding: "5px 15px",
                  color: "white",
                  background: "hsl(197, 65%, 29%)",
                  border: "1px solid hsl(197, 65%, 29%)",
                  fontSize: "14px",
                  fontWeight: "500",
                  transition: "0.15s",
                  position: "relative",
                }}
                onMouseEnter={(e) => {
                  e.target.style.background = "hsl(197, 65%, 35%)";
                }}
                onMouseLeave={(e) => {
                  e.target.style.background = "hsl(197, 65%, 29%)";
                }}
              >
                Add Service
              </button>
            </Link>
            <Box display={"flex"} gap={1}>
              {SelectedData.length > 0 && (
                <Select
                  size={"sm"}
                  placeholder="Export"
                  value={selectedOption}
                  onChange={handleSelectChange}
                  data-cy="export-drop-down"
                >
                  <option value="CSV">CSV</option>
                  <option value="PDF">PDF</option>
                  <option value="JSON">JSON</option>
                </Select>
              )}
              <CSVLink
                ref={csvLinkRef}
                filename="cml-estimate.csv"
                data={SelectedData.map((item, idx) => {
                  return {
                    Id: ++idx,
                    Name: item.name,
                    CPU: item.cpu,
                    Ram: item.ram >= 1024 ? item.ram / 1024 + " GB" : item.ram + " MB",
                    Vendor: item.vendor,
                    Quantity: item.quantity,
                    "Total CPU": item.quantity * item.cpu,
                    "Total RAM":
                      item.ram * item.quantity >= 1024
                        ? (item.ram * item.quantity) / 1024 + " GB"
                        : item.ram * item.quantity + " MB",
                  };
                })}
              >
                <Button
                  size={"sm"}
                  display={"none"}
                  colorScheme="gray"
                  variant="outline"
                  fontWeight={"400"}
                  padding={"0 25px"}
                >
                  Export to CSV
                </Button>
              </CSVLink>
            </Box>
          </Box>
        </Box>
        <TableContainer margin={"1rem 0 0 0"}>
          <Table size="sm">
            <Thead>
              <Tr
                textTransform={"capitalize"}
                fontWeight={"500"}
                background={"#262626"}
                color={"#fff"}
              >
                <Td data-cy="table-header">#</Td>
                <Td data-cy="table-header">Name</Td>
                <Td data-cy="table-header">CPU</Td>
                <Td data-cy="table-header">Ram</Td>
                <Td data-cy="table-header">Vendor</Td>
                <Td data-cy="table-header">Quantity</Td>
                <Td data-cy="table-header">Total CPU</Td>
                <Td data-cy="table-header">Total RAM</Td>
              </Tr>
            </Thead>
            <Tbody>
              {SelectedData?.map((item, idx) => {
                return (
                  <Tr key={idx}>
                    <Td width={"min-content"}>{++idx}</Td>
                    <Td>{item?.name}</Td>
                    <Td>{item?.cpu}</Td>
                    <Td>
                      {item?.ram >= 1024 ? item?.ram / 1024 + " GB" : item?.ram + " MB"}
                    </Td>
                    <Td>{item?.vendor}</Td>
                    <Td>{item?.quantity}</Td>
                    <Td>{item?.cpu * item?.quantity}</Td>
                    <Td>
                      {item?.ram * item?.quantity >= 1024
                        ? (item?.ram * item?.quantity) / 1024 + " GB"
                        : item?.ram * item?.quantity + " MB"}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
        <Box
          margin={"1rem 0 0.5rem 0"}
          color={"#6b7280"}
          display={"flex"}
          gap={"0.25reM"}
          data-cy="inquiry-submission"
        >
          <Text color={"red"} display={"inline-block"}>
            *
          </Text>
          Please forward your downloaded request to{" "}
          <a
            href="mailto:sales@cloudmylab.com"
            style={{textDecoration: "underline", textUnderlineOffset: "3px"}}
          >
            sales@cloudmylab.com
          </a>
          , and our team will be pleased to assist you with your inquiry.
        </Box>
      </>
    );
};

export default EstimateTable;
