import React, {useState, useEffect} from "react";
import orderBy from "lodash/orderBy";
import {Box, Table, Th, Thead, Tr} from "@chakra-ui/react";
import {FiArrowUp, FiArrowDown} from "@onemind-services-llc/react-icons-ng/fi";
import TableBody from "./TableBody";
import {useCalculator} from "../context/PocketCalculator";

const StripedTable = (props) => {
  const {data} = props;
  const {header} = useCalculator();
  const [sortedData, setSortedData] = useState([...data]);

  const [sortConfig, setSortConfig] = useState({key: "", direction: "asc"});

  useEffect(() => {
    let newData = data;
    if (sortConfig.key) {
      newData = orderBy(data, [sortConfig.key], [sortConfig.direction]);
    }
    setSortedData(newData);
  }, [data, sortConfig]);

  const handleHeaderClick = (newKey) => {
    let key = newKey;
    let direction = "asc";
    if (sortConfig.key === newKey && sortConfig.direction === "asc") {
      direction = "desc";
    } else if (sortConfig.key === newKey && sortConfig.direction === "desc") {
      key = "";
      direction = "";
    }
    setSortConfig({key, direction});
  };

  return (
    <>
      <Table>
        <Thead>
          <Tr
            background={"#1A5F7A"}
            h={"60px"}
            position={"sticky"}
            zIndex={"5"}
            top={"0"}
          >
            {header.map((item, index) => {
              return (
                <Th
                  data-cy="table-header"
                  fontSize={"16px"}
                  color={"white"}
                  key={index}
                  cursor={"pointer"}
                  textAlign={"center"}
                  onClick={() => handleHeaderClick(item.value)}
                >
                  {item.label}
                  {sortConfig.key === item.value &&
                    (sortConfig.direction === "asc" ? <FiArrowUp /> : <FiArrowDown />)}
                </Th>
              );
            })}
          </Tr>
        </Thead>
        <TableBody tableBodyData={sortedData} />
      </Table>
      {!data?.length && (
        <Box
          fontSize="20px"
          width="100%"
          minH="150px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          data-cy="no-results-found"
        >
          No Results Found
        </Box>
      )}
    </>
  );
};

export default StripedTable;
