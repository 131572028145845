import React, {useEffect} from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  useDisclosure,
  Box,
  Text,
  Link,
} from "@chakra-ui/react";
import {useCalculator} from "../context/PocketCalculator";
import SelectedListItem from "./SelectedListItem";
import ProductModalFooter from "./ProductModalFooter";
import {ImBin} from "@onemind-services-llc/react-icons-ng/im";

const ProductModal = () => {
  const {ShowModal, SelectedData, clearProductCart} = useCalculator();
  const {isOpen, onOpen, onClose} = useDisclosure();

  useEffect(() => {
    if (ShowModal) onOpen();
    else onClose();
  }, [ShowModal, onOpen, onClose]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered={true}>
      <ModalOverlay />
      <ModalContent
        position={"relative"}
        shadow={"md"}
        w={{
          base: "calc(100vw - 50px)",
          sm: "calc(100vw - 50px)",
          md: "calc(100vw - 100px)",
        }}
        maxW={"1000px"}
        borderRadius={"0"}
      >
        <ModalCloseButton
          minW={"32px"}
          maxW={"32px"}
          minH={"32px"}
          maxH={"32px"}
          color={"#9CA3AF"}
          fontSize={"16px"}
          border={"0"}
          outline="none"
          data-cy="product-modal-close-btn"
          _hover={{
            color: "gray",
          }}
        />
        <Box background={"#fafafa"} borderBottom={"1px solid #eaeded"} padding={"1rem"}>
          <Box display={"flex"} alignItems={"baseline"} gap={2}>
            <Text
              color={"#111b27"}
              fontSize={"18px"}
              fontWeight={"500"}
              data-cy="product-modal-heading"
            >
              Hosted Emulator
            </Text>
            <Link href="/about">
              <Text
                display={{
                  base: "none",
                  sm: "none",
                  md: "block",
                }}
                fontSize={"12px"}
                color={"#0073bb"}
                cursor={"pointer"}
                _hover={{textDecoration: "underline"}}
              >
                Info
              </Text>
            </Link>
          </Box>
        </Box>
        <Box
          minH={"400px"}
          maxH="400px"
          overflow={"auto"}
          display={"grid"}
          gridTemplateColumns={"1fr"}
          gridGap={"0.5rem 0.8rem"}
          padding={"1rem 0.5rem 3rem 1rem"}
          borderBottom={"1px solid #eee"}
        >
          {SelectedData.length > 0 ? (
            SelectedData.map((item, idx) => {
              return <SelectedListItem key={idx} {...item} />;
            })
          ) : (
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              flexDir={"column"}
              gridArea={"1 / 1 / 4 / 4"}
            >
              <Text>Select Some Virtual Machine</Text>
              <Text
                fontSize={"16px"}
                color={"#0073bb"}
                cursor={"pointer"}
                _hover={{textDecoration: "underline"}}
                onClick={() => {
                  onClose();
                  window.location.href = "/vendor/all-vendor";
                }}
                data-cy="see-all-vendor-link"
              >
                See All Vendor
              </Text>
            </Box>
          )}
        </Box>

        <Box
          display={"flex"}
          padding={{
            base: "0 1rem",
            sm: "0 1rem",
            md: "0 1rem",
            lg: "1rem 1rem 0 1rem",
          }}
        >
          <Text
            display={"inline-flex"}
            cursor={"pointer"}
            alignItems={"flex-start"}
            gap={1}
            color={"#1a5f7a"}
            background={"white"}
            fontWeight={"600"}
            fontSize={"15px"}
            margin={{
              base: "1rem 0 0 0",
              sm: "1rem 0 0 0",
              md: "1rem 0 0 0",
              lg: "0",
            }}
            onClick={clearProductCart}
            data-cy="clear-cart"
          >
            <ImBin
              style={{
                color: "#1A5F7A",
                fontSize: "17px",
                cursor: "pointer",
              }}
            />
            Clear Cart
          </Text>
        </Box>
        <ProductModalFooter
          onClose={onClose}
          totalCPU={SelectedData.reduce((acc, curr) => {
            return acc + curr.quantity * curr.cpu;
          }, 0)}
          totalRAM={SelectedData.reduce((acc, curr) => {
            return acc + curr.quantity * curr.ram;
          }, 0)}
        />
      </ModalContent>
    </Modal>
  );
};

export default ProductModal;
