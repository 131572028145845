import React from "react";
import {Flex, Link, Text} from "@chakra-ui/react";

const Footer = () => {
  return (
    <Flex
      justifyContent={"center"}
      alignItems={"center"}
      position={"fixed"}
      zIndex={"10"}
      bottom="0px"
      left="0px"
      right="0px"
      minH={"50px"}
      pl={{base: "10px", sm: "5vw", lg: "15vw"}}
      pr={{base: "10px", sm: "5vw", lg: "15vw"}}
      color={"#6B7280"}
      background={"white"}
      shadow={"sm"}
    >
      <Text>
        © 2016 - {new Date().getFullYear()}&nbsp;
        <Link
          href="https://cloudmylab.com/"
          target="_blank"
          style={{
            textDecoration: "underline",
            textUnderlineOffset: "3px",
          }}
          data-cy="footer-link"
        >
          CloudMyLab
        </Link>
        . All rights reserved.
      </Text>
    </Flex>
  );
};

export default Footer;
