import React from "react";
import {Box} from "@chakra-ui/react";
import {TableComponent} from "../Components";
import {Link, useParams} from "react-router-dom";

const Vendor = () => {
  const {vendor} = useParams();

  return (
    <Box
      p="min(2rem, 4%) 5% min(8rem, 8%)"
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
    >
      <Box width={"100%"} maxW={"7xl"}>
        <TableComponent vendor={vendor} />
        {vendor !== "all-vendor" && (
          <Box marginTop="2rem">
            <Link to={"/vendor/all-vendor"}>
              <button
                style={{
                  padding: "6px 20px",
                  color: "white",
                  background: "hsl(197, 65%, 29%)",
                  border: "1px solid hsl(197, 65%, 29%)",
                  fontWeight: "500",
                  transition: "0.15s",
                }}
                onMouseEnter={(e) => {
                  e.target.style.background = "hsl(197, 65%, 35%)";
                }}
                onMouseLeave={(e) => {
                  e.target.style.background = "hsl(197, 65%, 29%)";
                }}
              >
                Show All
              </button>
            </Link>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Vendor;
