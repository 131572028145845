import React from "react";
import {Box, Flex, Image, Link, Tooltip} from "@chakra-ui/react";
import BucketAlert from "./bucketAlert";
import {BsFillCalculatorFill} from "@onemind-services-llc/react-icons-ng/bs";
import {useCalculator} from "../context/PocketCalculator";
import {FcAbout} from "@onemind-services-llc/react-icons-ng/fc";
import {TbReportMoney} from "@onemind-services-llc/react-icons-ng/tb";
import {FaHome} from "@onemind-services-llc/react-icons-ng/fa";
import {LuContact} from "@onemind-services-llc/react-icons-ng/lu";

const Header = () => {
  const {SelectedData, setShowModal} = useCalculator();

  return (
    <Box
      position="sticky"
      top="0"
      zIndex="100"
      background={"white"}
      opacity={"1"}
      shadow={"sm"}
    >
      <Box
        pt="15px"
        pb="15px"
        pl={{base: "10px", sm: "5vw", lg: "15vw"}}
        pr={{base: "10px", sm: "5vw", lg: "15vw"}}
      >
        <Flex justifyContent={"space-between"} alignItems={"center"}>
          <Box display={"flex"} gap={10} alignItems={"center"}>
            <Tooltip label="CloudMyLab Resource Calculator" fontSize="md">
              <Link href={"/"} data-cy="nav-logo">
                <Image width={"60px"} src={"/company.png"} />
              </Link>
            </Tooltip>
            <Tooltip label="Home" fontSize="md">
              <Box>
                <Link href={"/"} data-cy="nav-link">
                  <FaHome
                    style={{
                      color: "#1A5F7A",
                      fontSize: "25px",
                      cursor: "pointer",
                    }}
                  />
                </Link>
              </Box>
            </Tooltip>
          </Box>
          <Box display={"flex"} alignItems={"center"} gap={3}>
            <Tooltip label="Resource Calculator" fontSize="md">
              <Link
                position={"relative"}
                onClick={() => setShowModal((prev) => prev + 1)}
                data-cy="nav-link"
              >
                <BsFillCalculatorFill
                  style={{
                    color: "#1A5F7A",
                    fontSize: "20px",
                    cursor: "pointer",
                  }}
                />
                <Box
                  position={"absolute"}
                  top={"-5px"}
                  right={"-2px"}
                  minW={SelectedData.length < 100 ? "15px" : "17px"}
                  maxW={SelectedData.length < 100 ? "15px" : "17px"}
                  minH={SelectedData.length < 100 ? "15px" : "17px"}
                  maxH={SelectedData.length < 100 ? "15px" : "17px"}
                  color={"white"}
                  fontWeight={"bold"}
                  background={"red"}
                  borderRadius={"50%"}
                  display={SelectedData.length > 0 ? "inline-flex" : "none"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  fontSize={"9px"}
                  data-cy="resource-calculator-quantity"
                >
                  {SelectedData.length < 100 ? SelectedData.length : "99+"}
                </Box>
              </Link>
            </Tooltip>

            <Tooltip label="Invoice" fontSize="md">
              <Link href={"/request"} data-cy="nav-link">
                <TbReportMoney
                  style={{
                    color: "#1A5F7A",
                    fontSize: "25px",
                  }}
                />
              </Link>
            </Tooltip>
            <Tooltip label="About Us" fontSize="md">
              <Link href={"/about"} data-cy="nav-link">
                <FcAbout
                  style={{
                    fontSize: "25px",
                  }}
                />
              </Link>
            </Tooltip>
            <Tooltip label="Contact Us" fontSize="md">
              <Link
                href={"https://cloudmylab.com/contact-us"}
                target="_blank"
                data-cy="nav-link"
              >
                <LuContact style={{color: "#1A5F7A", fontSize: "25px"}} />
              </Link>
            </Tooltip>
          </Box>
        </Flex>
      </Box>
      <BucketAlert />
    </Box>
  );
};

export default Header;
