import React from "react";
import {createRoot} from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {ChakraProvider} from "@chakra-ui/react";
import {PocketCalculatorProvider} from "./context/PocketCalculator";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <ChakraProvider>
    <PocketCalculatorProvider>
      <App />
    </PocketCalculatorProvider>
  </ChakraProvider>
);

reportWebVitals();
