import React from "react";
import {Box} from "@chakra-ui/react";
import ProductCard from "./productCard";

const CardList = (props) => {
  const {data} = props;

  return (
    <div>
      {data.length > 0 ? (
        data.map((cardData, index) => (
          <ProductCard key={index} idx={index} data={cardData} />
        ))
      ) : (
        <Box padding={"1rem 0 0 0"} data-cy="mobile-no-results-found">
          No Results Found
        </Box>
      )}
    </div>
  );
};

export default CardList;
