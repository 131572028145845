import React, {useContext} from "react";
import {
  Tbody,
  Td,
  Tr,
  Flex,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";
import {PocketCalculator} from "../context/PocketCalculator";

const TableBody = (props) => {
  const {tableBodyData} = props;
  const {quantityChangeFunction} = useContext(PocketCalculator);

  return (
    <>
      {tableBodyData?.length > 0 && (
        <Tbody>
          {tableBodyData.map((item, idx) => {
            const {name, cpu, ram, id, quantity} = item;

            return (
              <Tr key={id}>
                <Td data-cy="name-column" textAlign={"center"}>
                  {name}
                </Td>
                <Td data-cy="cpu-column" textAlign={"center"}>
                  {cpu}
                </Td>
                <Td data-cy="ram-column" textAlign={"center"}>
                  {ram >= 1024 ? ram / 1024 + " GB" : ram + " MB"}
                </Td>
                <Td data-cy="quantity-column">
                  <Flex alignItems={"center"} justifyContent={"center"}>
                    <NumberInput
                      size="sm"
                      id={"node"}
                      maxW={24}
                      min={0}
                      background={"#f9fafb"}
                      value={quantity}
                      onChange={(value) => {
                        let n = Math.floor(Number(value));
                        if (n !== Infinity && n >= 0 && n !== quantity) {
                          quantityChangeFunction(n, id);
                        }
                      }}
                    >
                      <NumberInputField data-cy={"quantity-input"} />
                      <NumberInputStepper>
                        <NumberIncrementStepper data-cy={"increment-btn"} />
                        <NumberDecrementStepper data-cy={"decrement-btn"} />
                      </NumberInputStepper>
                    </NumberInput>
                  </Flex>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      )}
    </>
  );
};

export default TableBody;
