import {Box, Text, Heading} from "@chakra-ui/react";
import React from "react";
import {EstimateTable} from "../Components";

const Request = () => {
  return (
    <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
      <Box
        padding={"min(4%, 4rem) 5%"}
        width={"100%"}
        maxW={"7xl"}
        display={"flex"}
        flexWrap={"wrap"}
        gap={2}
      >
        <Box
          width={"100%"}
          display={"flex"}
          justifyContent={{
            base: "center",
            sm: "center",
            md: "space-between",
            lg: "space-between",
          }}
          flexDir={{
            base: "column",
            sm: "column",
            md: "row",
            lg: "row",
          }}
          gap={2}
          id="company-info"
        >
          <Box>
            <Box
              width={"100%"}
              minH={"min(40vh, 120px)"}
              backgroundImage={"/logo.webp"}
              backgroundSize={"contain"}
              backgroundPosition={"left center"}
              backgroundRepeat={"no-repeat"}
              margin={"0 0 1rem 0"}
            />
            <Heading fontSize={"lg"} data-cy="company-heading">
              CloudMyLab - A OneMind Service Enterprise
            </Heading>
            <Text data-cy="company-address">11501 Dublin Blvd,</Text>
            <Text data-cy="company-address">Suite 200,</Text>
            <Text data-cy="company-address">Dubline, California 94568</Text>
            <Text data-cy="company-address">U.S.A</Text>
            <Text>&nbsp;</Text>
          </Box>
          <Box>
            <Heading
              letterSpacing={"2px"}
              padding={"0 0 1rem 0"}
              fontWeight={500}
              data-cy="estimate-title"
            >
              REQUEST
            </Heading>
          </Box>
        </Box>
        <Box width={"100%"}>
          {/* Table */}
          <EstimateTable />
        </Box>
      </Box>
    </Box>
  );
};

export default Request;
