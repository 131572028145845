import React, {useState, useEffect} from "react";
import {Box, Input, InputGroup, InputLeftElement, useMediaQuery} from "@chakra-ui/react";
import {BiSearch} from "@onemind-services-llc/react-icons-ng/bi";
import StripedTable from "./StripedTable";
import CardList from "./cardList";
import {useCalculator} from "../context/PocketCalculator";

const TableComponent = (props) => {
  const {vendor} = props;
  const {EntireData} = useCalculator();
  const [searchKey, setSearchKey] = useState("");
  const [filteredData, setFilteredData] = useState(EntireData);
  const [isMobile] = useMediaQuery("(max-width: 600px)");

  useEffect(() => {
    if (vendor !== "all-vendor")
      setFilteredData(EntireData.filter((item) => item.vendor === vendor));
    else setFilteredData(EntireData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (vendor !== "all-vendor")
      setFilteredData(EntireData.filter((item) => item.vendor === vendor));
    else setFilteredData(EntireData);
  }, [vendor, EntireData]);

  useEffect(() => {
    let newData = [];
    if (vendor !== "all-vendor")
      newData = EntireData.filter((item) => item.vendor === vendor);
    else newData = EntireData;

    if (searchKey) {
      newData = newData?.filter(
        (row) => row["name"]?.toLowerCase().indexOf(searchKey) > -1
      );
    }
    setFilteredData(newData);
  }, [searchKey, EntireData, vendor]);

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent={"flex-end"}>
        <InputGroup w="250px">
          <InputLeftElement
            pointerEvents="none"
            children={<BiSearch color="gray.300" />}
          />
          <Input
            type="search"
            placeholder="Filter Name..."
            value={searchKey}
            onChange={(e) => setSearchKey(e.target.value)}
            background={"#f9fafb"}
            color={"#111827"}
            border={"1px solid #d1d5d8"}
            data-cy="table-search-input"
          />
        </InputGroup>
      </Box>
      <Box mt="10px">
        {isMobile ? (
          <CardList data={filteredData} />
        ) : (
          <StripedTable data={filteredData} />
        )}
      </Box>
    </>
  );
};

export default TableComponent;
