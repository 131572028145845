import React from "react";
import {Box, Button, Heading, VStack} from "@chakra-ui/react";
import {Link} from "react-router-dom";

const PageNotFound = () => {
  return (
    <VStack
      px={{base: 6, sm: 8, md: 12, lg: 24}}
      py={{base: 6, sm: 8, md: 12}}
      spacing={4}
    >
      <img
        alt=""
        height={"200px"}
        placeholder="blur"
        data-cy="not-found-img"
        src={"/notFound.jpg"}
        style={{borderRadius: "8px"}}
        width={"300px"}
      />
      <Heading textAlign="center" data-cy="not-found-title">
        Oops! Page not found.
      </Heading>
      <Box textAlign="center" data-cy="not-found-description">
        We're sorry, but the page you are looking for cannot be found.
      </Box>
      <Link to={"/"} data-cy="not-found-link">
        <Button size="lg" minW={{base: "full", sm: "10rem"}} variant={"outline"}>
          RETURN TO HOME PAGE
        </Button>
      </Link>
    </VStack>
  );
};

export default PageNotFound;
