import React, {useEffect, useState} from "react";
import {
  Box,
  Alert,
  AlertIcon,
  AlertDescription,
  Flex,
  CloseButton,
} from "@chakra-ui/react";
import {useCalculator} from "../context/PocketCalculator";

const BucketAlert = () => {
  const {SelectedData} = useCalculator();
  const [isOpen, setOpen] = useState(false);
  const [manuallyClosed, setManuallyClosed] = useState(false);

  useEffect(() => {
    if (SelectedData.length > 0 && !manuallyClosed) setOpen(true);
    if (SelectedData.length === 0) setOpen(false);
  }, [manuallyClosed, SelectedData]);

  return (
    <Box>
      {isOpen && (
        <Alert status="info">
          <Flex justifyContent={"center"} alignItems="center" width="100%">
            <AlertIcon />
            <AlertDescription>
              Item's have been added to your bucket. Click on bucket to check the
              suggested package
            </AlertDescription>
            <CloseButton
              onClick={() => {
                setOpen(false);
                setManuallyClosed(true);
              }}
            />
          </Flex>
        </Alert>
      )}
    </Box>
  );
};

export default BucketAlert;
