import React, {useEffect} from "react";
import {Box, FormControl, Link, Text} from "@chakra-ui/react";
import Select from "react-select";
import {MdPermContactCalendar} from "@onemind-services-llc/react-icons-ng/md";
import {useCalculator} from "../context/PocketCalculator";

const dropdownStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: "1px dotted #1A5F7A",
    color: "#16191f",
    background: "white",
    padding: "7px 20px",
    ":hover": {
      background: "#1A5F7A",
      color: "white",
    },
  }),
  control: (provided) => ({
    ...provided,
    backgroundColor: "white",
    padding: 0,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    color: "#1A5F7A",
  }),
};

const ProductModalFooter = (props) => {
  const {totalCPU, totalRAM, onClose} = props;
  const {
    selectedPlan,
    setSelectedPLan,
    billingCycle,
    setBillingCycle,
    recommendedPlan,
    calcRecommendedPlan,
    // Operating System DropDown
    selectedOS,
    setSelectedOS,
    operating_system,
    // Billing Cycle DropDown
    OsOptions,
    setOsOptions,
    BillingCycleData,
    setBillingCycleData,
    // caching
    setCachePackage,
    setCacheOS,
    setCacheBillingCycle,
  } = useCalculator();

  useEffect(() => {
    calcRecommendedPlan();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePackageChange = (event) => {
    setSelectedPLan(event);

    setOsOptions((prev) => {
      return operating_system[event?.value?.os];
    });

    setSelectedOS(operating_system?.[event?.value?.os]?.[0]);

    if (operating_system?.[event?.value?.os]?.[0]?.price)
      setBillingCycleData((prev) => {
        prev = prev.map((item) => {
          return {
            ...item,
            isDisabled: !(
              operating_system?.[event?.value?.os]?.[0]?.price &&
              item.label?.toLowerCase() in
                operating_system?.[event?.value?.os]?.[0]?.price
            ),
          };
        });
        let val = -1;
        prev.forEach((item) => {
          if (!item.isDisabled && val === -1) val = item;
        });
        setBillingCycle(val);

        setCachePackage(event);
        setCacheOS(operating_system?.[event?.value?.os]?.[0]);
        setCacheBillingCycle(val);
        return prev;
      });
  };

  const handleOSChange = (event) => {
    setSelectedOS(event);

    setBillingCycleData((prev) => {
      prev = prev?.map((item) => {
        return {
          ...item,
          isDisabled: !(item?.label?.toLowerCase() in event?.price),
        };
      });
      let val = -1;
      prev.forEach((item) => {
        if (!item?.isDisabled && val === -1) val = item;
      });
      setBillingCycle(val);
      setCacheOS(event);
      setCacheBillingCycle(val);
      return prev;
    });
  };

  return (
    <>
      <Box
        minH={"40px"}
        color={"#16191f"}
        display={"grid"}
        gridTemplateColumns={{
          base: "1fr",
          sm: "1fr",
          md: "1fr",
          lg: "1fr 2.25fr",
        }}
        alignItems={"baseline"}
        justifyContent={"space-between"}
        margin={"0.5rem 0 0 0"}
        fontSize={"14px"}
        padding={{
          base: "0 1rem",
          sm: "0 1rem",
          md: "0 1rem",
          lg: "1rem 1rem 0 1rem",
        }}
      >
        <Box
          display={{base: "none", sm: "none", md: "none", lg: "flex"}}
          gap={2}
          height={"100%"}
        >
          <Box
            padding={"0 0.75rem 0 0"}
            borderRight={".2rem solid #d5dbdb"}
            height={"100%"}
          >
            <Box display={"flex"} gap={2}>
              <Text color={"#111b27"} fontSize={"14px"} fontWeight={"500"}>
                Total CPU:
              </Text>
              <Text>{totalCPU}</Text>
            </Box>
            <Box display={"flex"} gap={2}>
              <Text color={"#111b27"} fontSize={"14px"} fontWeight={"500"}>
                Total RAM:
              </Text>
              <Text>{totalRAM >= 1024 ? totalRAM / 1024 + " GB" : totalRAM + " MB"}</Text>
            </Box>
            {selectedPlan?.label !== "CUSTOM" && (
              <>
                {selectedPlan?.value?.price?.[billingCycle?.label?.toLowerCase()] +
                selectedOS?.price?.[billingCycle?.label?.toLowerCase()] ? (
                  <Box display={"flex"} gap={2}>
                    <Text color={"#111b27"} fontSize={"14px"} fontWeight={"500"}>
                      Total Cost:
                    </Text>
                    <Text>
                      $
                      {selectedPlan?.value?.price?.[billingCycle?.label?.toLowerCase()] +
                        selectedOS?.price?.[billingCycle?.label?.toLowerCase()]}
                      &nbsp;USD
                    </Text>
                  </Box>
                ) : (
                  ""
                )}
              </>
            )}
          </Box>
        </Box>
        <Box
          display={"grid"}
          gridTemplateColumns={{
            base: "1fr",
            sm: "1fr",
            md: "1fr 1fr",
            lg: "1.5fr 1.5fr 1fr",
          }}
          gridGap={"0.5rem"}
        >
          <FormControl data-cy="package">
            <Text>Package</Text>
            <Box>
              <Select
                value={selectedPlan}
                placeholder={"select..."}
                options={recommendedPlan}
                onChange={handlePackageChange}
                styles={dropdownStyles}
                menuPlacement="top"
                isSearchable={false}
              />
            </Box>
          </FormControl>
          <FormControl data-cy="operating-system">
            <Text fontSize={"14px"}>Operating System</Text>
            <Box>
              <Select
                value={selectedOS}
                placeholder={"select..."}
                options={OsOptions}
                onChange={handleOSChange}
                styles={dropdownStyles}
                menuPlacement="top"
                isSearchable={false}
              />
            </Box>
          </FormControl>
          <FormControl data-cy="billing-cycle">
            <Text>Billing Cycle</Text>
            <Box>
              <Select
                value={billingCycle}
                placeholder={"select..."}
                options={BillingCycleData}
                onChange={(value) => setBillingCycle(value)}
                styles={{
                  ...dropdownStyles,
                  option: (provided, state) => ({
                    ...provided,
                    borderBottom: "1px dotted #1A5F7A",
                    color: "#16191f",
                    opacity: state.data.isDisabled ? 0.5 : 1,
                    background: "white",
                    padding: "7px 20px",
                    ":hover": {
                      background: "#1A5F7A",
                      color: "white",
                    },
                  }),
                }}
                menuPlacement="top"
                isSearchable={false}
              />
            </Box>
          </FormControl>
        </Box>
        <Box
          display={{base: "flex", sm: "flex", md: "flex", lg: "none"}}
          gap={2}
          height={"100%"}
          margin={"0.5rem 0 0 0"}
        >
          <Box padding={"0 0.75rem 0 0"} height={"100%"}>
            <Box display={"flex"} gap={2}>
              <Text color={"#111b27"} fontSize={"14px"} fontWeight={"500"}>
                Total CPU:
              </Text>
              <Text data-cy="total-cpu">{totalCPU}</Text>
            </Box>
            <Box display={"flex"} gap={2}>
              <Text color={"#111b27"} fontSize={"14px"} fontWeight={"500"}>
                Total RAM:
              </Text>
              <Text data-cy="total-ram">
                {totalRAM >= 1024 ? totalRAM / 1024 + " GB" : totalRAM + " MB"}
              </Text>
            </Box>
            {selectedPlan?.label !== "CUSTOM" && (
              <>
                {selectedPlan?.value?.price?.[billingCycle?.label?.toLowerCase()] +
                selectedOS?.price?.[billingCycle?.label?.toLowerCase()] ? (
                  <Box display={"flex"} gap={2}>
                    <Text color={"#111b27"} fontSize={"14px"} fontWeight={"500"}>
                      Total Cost:
                    </Text>
                    <Text data-cy="total-cost">
                      $
                      {selectedPlan?.value?.price?.[billingCycle?.label?.toLowerCase()] +
                        selectedOS?.price?.[billingCycle?.label?.toLowerCase()]}
                      &nbsp;USD
                    </Text>
                  </Box>
                ) : (
                  ""
                )}
              </>
            )}
          </Box>
        </Box>
      </Box>
      <Box
        minH={"40px"}
        color={"#16191f"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        fontSize={"14px"}
        padding={{
          base: "0.25rem 1rem 1rem 1rem",
          sm: "0.25rem 1rem 1rem 1rem",
          md: "0.25rem 1rem 1rem 1rem",
          lg: "1rem",
        }}
        gap={2}
        flexWrap={"wrap"}
      >
        <Link href="/request" data-cy="view-summary-button">
          <button
            style={{
              padding: "6px 15px",
              color: "#545b64",
              border: "1px solid #545b64",
              fontWeight: "700",
            }}
            onClick={() => {
              onClose();
            }}
            onMouseEnter={(e) => (e.target.style.background = "#fafafa")}
            onMouseLeave={(e) => (e.target.style.background = "#ffffff")}
          >
            Save and view summary
          </button>
        </Link>
        {totalRAM / 1024 <= 1024 ? (
          <Link
            href={`https://dashboard.cloudmylab.com/app/add-product/Hosted-Services?os=${selectedOS?.id}&plan=${selectedPlan?.value?.SKU}&frequency=${billingCycle.value}`}
          >
            <button
              style={{
                padding: "6px 20px",
                color: "white",
                background: "#1A5F7A",
                border: "1px solid #1A5F7A",
                fontWeight: "700",
              }}
            >
              <i className="fas fa-server" />
              &nbsp; Buy Package
            </button>
          </Link>
        ) : (
          <Link href="https://cloudmylab.com/contact-us">
            <button
              style={{
                padding: "6px 20px",
                color: "white",
                background: "#1A5F7A",
                border: "1px solid #1A5F7A",
                fontWeight: "700",
                display: "flex",
                alignItems: "center",
              }}
            >
              <MdPermContactCalendar />
              &nbsp; Contact Us
            </button>
          </Link>
        )}
      </Box>
    </>
  );
};

export default ProductModalFooter;
