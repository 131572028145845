import {Box, Text, UnorderedList, ListItem, Heading} from "@chakra-ui/react";
import React from "react";

const About = () => {
  return (
    <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
      <Box padding={"min(2%, 4rem) 5%"} maxW={"7xl"}>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          margin={"0 0 1rem 0"}
        >
          <Heading
            data-aos="fade-up"
            position={"relative"}
            color={"#2d3728"}
            textTransform={"capitalize"}
            fontFamily={"Poppins"}
            fontWeight={"500"}
            display={"inline-block"}
            data-cy="about-title"
          >
            About Us
          </Heading>
        </Box>
        <Text margin={"0.5rem 0"} data-cy="about-content">
          The purpose of this calculator is to determine the resources required to run
          various vendor images on Following Hosted Emulator-
        </Text>
        <UnorderedList>
          <ListItem data-cy="network-list-item">EVE-NG community</ListItem>
          <ListItem data-cy="network-list-item">EVE-NG Professional</ListItem>
          <ListItem data-cy="network-list-item">GNS3</ListItem>
          <ListItem data-cy="network-list-item">
            Cisco Modelling Labs (limited Cisco Images only)
          </ListItem>
        </UnorderedList>
      </Box>
    </Box>
  );
};

export default About;
