import React from "react";
import {
  Box,
  VStack,
  Text,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
} from "@chakra-ui/react";
import {useCalculator} from "../context/PocketCalculator";

const ProductCard = (props) => {
  const {data} = props;
  const {quantityChangeFunction} = useCalculator();
  const {name, cpu, ram, id, quantity} = data;

  return (
    <Box
      display={"grid"}
      gridTemplateColumns={{
        base: "1fr",
        sm: "1fr",
        md: "1fr 2fr",
      }}
      borderBottom={"1px solid #eaeded"}
      height={"min-content"}
      margin={"0.75rem 0 0 0"}
    >
      <VStack
        pr={"5px"}
        alignItems={"left"}
        mb={"10px"}
        height={"95%"}
        w={"100%"}
        color={"#111b27"}
        padding={"0.5rem"}
        transition={"0.15s"}
        fontSize={"14px"}
      >
        <Text
          fontWeight={"500"}
          fontSize={"md"}
          textAlign={"left"}
          pr={"20px"}
          data-cy={"mobile-name"}
        >
          {name}
        </Text>
        <Box display={"flex"} gap={2} justifyContent={"space-between"}>
          <Box color={"#6b7280"}>
            <Text fontSize={"sm"} pr={"10px"} data-cy={"mobile-cpu"}>
              CPU : {cpu}
            </Text>
            <Text fontSize={"sm"} data-cy={"mobile-ram"}>
              RAM : {ram >= 1024 ? ram / 1024 + " GB" : ram + " MB"}
            </Text>
          </Box>
          <Box color={"#6b7280"}>
            <Box display={"flex"} alignItems={"center"} gap={2} color={"#6b7280"}>
              Qty
              <NumberInput
                size="sm"
                id={"node"}
                maxW={20}
                value={quantity}
                min={0}
                onChange={(value) => {
                  let n = Math.floor(Number(value));
                  if (n !== Infinity && n >= 0 && n !== quantity) {
                    quantityChangeFunction(n, id);
                  }
                }}
              >
                <NumberInputField data-cy={"mobile-quantity-input"} />
                <NumberInputStepper>
                  <NumberIncrementStepper data-cy={"mobile-increment-btn"} />
                  <NumberDecrementStepper data-cy={"mobile-decrement-btn"} />
                </NumberInputStepper>
              </NumberInput>
            </Box>
          </Box>
        </Box>
      </VStack>
    </Box>
  );
};

export default ProductCard;
