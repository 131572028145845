import React, {useEffect} from "react";
import {Box, Flex, Heading, Image, useToast} from "@chakra-ui/react";
import {Link} from "react-router-dom";

const categories = [
  {
    name: "Arista",
    path: "/logos/arista.png",
  },
  {
    name: "Aruba",
    path: "/logos/aruba.png",
  },
  {
    name: "Cisco",
    path: "/logos/cisco.png",
  },
  {
    name: "Fortinet",
    path: "/logos/fortinet.png",
  },
  {
    name: "Juniper",
    path: "/logos/juniper.jpg",
  },
  {
    name: "Microsoft",
    path: "/logos/microsoft.png",
  },
  {
    name: "Nokia",
    path: "/logos/nokia.jpg",
  },
  {
    name: "Paloalto",
    path: "/logos/paloalto.png",
  },
  {
    name: "VmWare",
    path: "/logos/Vmware.png",
  },
  {
    name: "Other",
    path: null,
  },
];

const Home = () => {
  const toast = useToast();

  useEffect(() => {
    toast({
      description:
        "All calculation results are estimate and may differ from real-life usage scenario.",
      status: "warning",
      variant: "top-accent",
      position: "top-right",
      isClosable: true,
      duration: 9000,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const CategoryBox = ({category}) => {
    return (
      <Flex
        justifyContent={"center"}
        alignItems={"center"}
        width="140px"
        height="140px"
        margin="20px"
        border={"1px solid #eee"}
        shadow={
          "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px"
        }
        borderRadius="10px"
        cursor="pointer"
        transition={"0.15s"}
        _hover={{
          transform: "translateY(-5px)",
          shadow:
            "rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px",
        }}
      >
        {category.path ? (
          <Image padding={"10px"} src={category.path} pointerEvents="none" />
        ) : (
          <Heading color={"#383838"}>{category.name}</Heading>
        )}
      </Flex>
    );
  };

  return (
    <Box margin={"2rem 0 0 0"}>
      {/* <Heading textAlign={"center"} size={"lg"} color={"#1A5F7A"}></Heading> */}
      <Box
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        margin={"0 0 1rem 0"}
      >
        <Heading
          data-aos="fade-up"
          position={"relative"}
          color={"#1A5F7A"}
          textTransform={"capitalize"}
          fontFamily={"Poppins"}
          fontSize={"3xl"}
          fontWeight={"500"}
          display={"inline-block"}
          data-cy="home-heading"
        >
          Select Vendor
        </Heading>
      </Box>
      <Flex
        justifyContent={"center"}
        flexWrap={"wrap"}
        w={{base: "100%", sm: "80vw", lg: "75vw", xl: "55vw"}}
        margin="auto"
      >
        {categories.map((category, idx) => {
          return (
            <Link
              data-cy={`vendor-card-${category.name}`}
              to={`/vendor/${category.name}`}
              key={idx}
            >
              <CategoryBox category={category} />
            </Link>
          );
        })}
      </Flex>
      <Box
        margin={"1rem 0 5rem 0"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Link to={"/vendor/all-vendor"} data-cy="show-all-button">
          <button
            style={{
              padding: "6px 20px",
              color: "white",
              background: "hsl(197, 65%, 29%)",
              border: "1px solid hsl(197, 65%, 29%)",
              fontWeight: "500",
              transition: "0.15s",
            }}
            onMouseEnter={(e) => {
              e.target.style.background = "hsl(197, 65%, 35%)";
            }}
            onMouseLeave={(e) => {
              e.target.style.background = "hsl(197, 65%, 29%)";
            }}
          >
            Show All
          </button>
        </Link>
      </Box>
    </Box>
  );
};

export default Home;
