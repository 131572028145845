import React from "react";

import {
  Box,
  Heading,
  HStack,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
  Image,
  VStack,
} from "@chakra-ui/react";
import {useCalculator} from "../context/PocketCalculator";

const categories = {
  Arista: "/logos/arista.png",
  Aruba: "/logos/aruba.png",
  Cisco: "/logos/cisco.png",
  Fortinet: "/logos/fortinet.png",
  Juniper: "/logos/juniper.jpg",
  Microsoft: "/logos/microsoft.png",
  Nokia: "/logos/nokia.jpg",
  Paloalto: "/logos/paloalto.png",
  VmWare: "/logos/Vmware.png",
  Other: null,
};

const SelectedListItem = (props) => {
  const {quantityChangeFunction} = useCalculator();
  const {id, name, cpu, ram, quantity, vendor} = props;

  return (
    <Box
      display={"grid"}
      gridTemplateColumns={{
        base: "1fr",
        sm: "1fr",
        md: "1fr 2fr",
      }}
      borderBottom={"1px solid #eaeded"}
      height={"min-content"}
    >
      <Box
        margin={"0.5rem 0.5rem 0.75rem 0.5rem"}
        display={{
          base: "none",
          sm: "none",
          md: "flex",
        }}
        alignItems={"center"}
        justifyContent={"center"}
        position={"relative"}
      >
        {categories[vendor] ? (
          <Image
            padding={"10px"}
            width={"150px"}
            src={categories[vendor]}
            pointerEvents="none"
          />
        ) : (
          <Heading color={"#383838"}>{vendor}</Heading>
        )}
        <Box
          position={"absolute"}
          top={vendor === "Cisco" ? "90%" : "70%"}
          left={"50%"}
          transform={"translate(-50%, -50%)"}
          color={"#6b7280"}
          fontSize={"12px"}
          width={"100%"}
          textAlign={"center"}
        >
          {name}
        </Box>
      </Box>
      <VStack
        pr={"5px"}
        alignItems={"left"}
        mb={"10px"}
        height={"95%"}
        w={"100%"}
        color={"#111b27"}
        padding={"0.5rem"}
        transition={"0.15s"}
        fontSize={"14px"}
      >
        <Text fontWeight={"500"} fontSize={"md"} textAlign={"left"} pr={"20px"}>
          {name}
        </Text>
        <Box
          display={"grid"}
          gridTemplateColumns={{
            base: "1fr 1fr",
            sm: "1.5fr 1fr",
            md: "1.5fr 1fr",
            lg: "2fr 1fr",
          }}
          gridGap={2}
        >
          <Box color={"#6b7280"}>
            <Text fontSize={"sm"} pr={"10px"}>
              CPU : {cpu}
            </Text>
            <Text fontSize={"sm"}>
              RAM : {ram >= 1024 ? ram / 1024 + " GB" : ram + " MB"}
            </Text>
          </Box>
          <Box color={"#6b7280"}>
            <HStack>
              <Text size={"xs"}>Total CPU:</Text>
              <Text color={"#111b27"}>{cpu * quantity}</Text>
            </HStack>
            <HStack>
              <Text size={"xs"}>Total RAM:</Text>
              <Text color={"#111b27"}>
                {ram * quantity >= 1024
                  ? (ram * quantity) / 1024 + " GB"
                  : ram * quantity + " MB"}
              </Text>
            </HStack>
          </Box>
        </Box>
        <Box
          display={"grid"}
          gridTemplateColumns={{
            base: "1fr 1fr",
            sm: "2fr 1fr",
            md: "1.5fr 1fr",
            lg: "2fr 1fr",
          }}
          gridGap={2}
        >
          <Box display={"flex"} alignItems={"center"} gap={2} color={"#6b7280"}>
            Qty
            <NumberInput
              size="sm"
              id={"node"}
              maxW={20}
              value={quantity}
              min={0}
              onChange={(value) => {
                let n = Math.floor(Number(value));
                if (n !== Infinity && n >= 0 && n !== quantity) {
                  quantityChangeFunction(n, id);
                }
              }}
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </Box>
          <Text
            fontSize={"14px"}
            fontWeight={"600"}
            letterSpacing={"1"}
            color={"hsl(346, 100%, 42%)"}
            cursor={"pointer"}
            onClick={() => quantityChangeFunction(0, id)}
            _hover={{
              color: "hsl(346, 100%, 60%)",
            }}
          >
            Remove
          </Text>
        </Box>
      </VStack>
    </Box>
  );
};

export default SelectedListItem;
