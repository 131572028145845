import React, {createContext, useContext, useState, useEffect} from "react";
import {useToast} from "@chakra-ui/react";
import dataSet from "../DataSet/dataset.json";
import cloud_hosted from "../DataSet/cloud_hosted.json";
import baremetal from "../DataSet/baremetal.json";
import baremetal_cluter from "../DataSet/baremetal_cluter.json";
import operating_system from "../DataSet/operatingSystem.json";
import useLocalStorage from "use-local-storage";

export const PocketCalculator = createContext({});

export const useCalculator = () => useContext(PocketCalculator);

const header = [
  {label: "NAME", value: "name"},
  {label: "CPU", value: "cpu"},
  {label: "RAM", value: "ram"},
  {label: "QUANTITY", value: "quantity"},
];

const billingCycleOptions = [
  {label: "Weekly", value: "1_week", isDisabled: false},
  {label: "Monthly", value: "1_month", isDisabled: false},
  {label: "Yearly", value: "1_year", isDisabled: false},
];

export const PocketCalculatorProvider = (props) => {
  const {children} = props;
  const toast = useToast();
  const [EntireData, SetEntireData] = useState(dataSet);
  const [SelectedData, setSelectedData] = useState(dataSet);
  const [ShowModal, setShowModal] = useState(false);
  const [virtualMachinePlan] = useState([
    ...cloud_hosted.map((item) => {
      return {
        label: item.type,
        value: item,
      };
    }),
    ...baremetal.map((item) => {
      return {
        label: item.processor,
        value: item,
      };
    }),
    ...baremetal_cluter.map((item) => {
      return {
        label: item.processor,
        value: item,
      };
    }),
    {
      label: "CUSTOM",
      value: {memory: Infinity, os: "CUSTOM"},
    },
  ]);
  const [recommendedPlan, setRecommendedPlan] = useState([...virtualMachinePlan]);
  const [selectedPlan, setSelectedPLan] = useState(virtualMachinePlan[0]);
  const [BillingCycleData, setBillingCycleData] = useState(billingCycleOptions);
  const [billingCycle, setBillingCycle] = useState(billingCycleOptions[0]);

  // operating system
  const [OsOptions, setOsOptions] = useState(operating_system["Cloud Hosted"]);
  const [selectedOS, setSelectedOS] = useState(operating_system["Cloud Hosted"][0]);

  // Caching
  const [cacheSelectedPlan, setCacheSelectedPlan] = useLocalStorage("selectedPlan", []);
  const [cachePackage, setCachePackage] = useLocalStorage("package", null);
  const [cacheOS, setCacheOS] = useLocalStorage("os", null);
  const [cacheBillingCycle, setCacheBillingCycle] = useLocalStorage(
    "billing_cycle",
    null
  );

  // Request Id
  const [requestId, setRequestId] = useState(0);

  const getRequestId = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, "0");
    const day = now.getDate().toString().padStart(2, "0");
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");

    return `${year}${month}${day}${hours}${minutes}`;
  };

  useEffect(() => {
    const arr = EntireData.map((item) => ({
      ...item,
      quantity: cacheSelectedPlan.find((_item) => item.id === _item.id)?.quantity || 0,
    }));

    SetEntireData(arr);
    setSelectedData(arr.filter((item) => item.quantity !== 0));
    calcRecommendedPlan();
    setRecommendedPlan([...virtualMachinePlan]);

    const generatedRequestId = getRequestId();
    setRequestId(generatedRequestId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const calcRecommendedPlan = () => {
    let totalRAM =
      SelectedData.reduce((acc, curr) => acc + curr?.quantity * curr.ram, 0) / 1024;

    let dummyList = virtualMachinePlan.filter((item) => {
      return item.value?.memory >= totalRAM;
    });

    setRecommendedPlan(dummyList);
    setSelectedPLan(dummyList[0]);

    setOsOptions(operating_system[dummyList?.[0]?.value?.os] || []);

    // Caching package, os, billing cycle
    if (cachePackage?.hasOwnProperty("label")) setSelectedPLan(cachePackage);
    if (cacheOS?.hasOwnProperty("label")) setSelectedOS(cacheOS);
    if (cacheBillingCycle?.hasOwnProperty("label")) setBillingCycle(cacheBillingCycle);
  };

  const quantityChangeFunction = (value = 0, id) => {
    let arr = EntireData.map((item) => {
      if (id === item.id) {
        if (item.quantity === 0 && value === 1) {
          toast({
            title: "Added Successfully.",
            description: "New item added to calculator successfully.",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
        } else if (item.quantity >= 1 && value === 0) {
          toast({
            title: "Removed Successfully.",
            description: "New item removed to calculator successfully.",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        } else if (item.quantity < value || item.quantity > value) {
          toast({
            title: "Quantity Updated",
            description: "Item quantity successfully modified.",
            status: "success",
            duration: 1000,
            isClosable: true,
          });
        }
        return {
          ...item,
          quantity: value,
        };
      } else return {...item};
    });

    // Select whose quantity is greater than 0 for show result part
    SetEntireData(arr);

    arr = arr.filter((item) => {
      return item?.quantity !== 0;
    });
    setSelectedData(arr);

    // Product Modal
    let totalRAM = arr.reduce((acc, curr) => acc + curr?.quantity * curr.ram, 0) / 1024;

    let dummyList = virtualMachinePlan.filter((item) => {
      return item.value?.memory >= totalRAM;
    });

    setRecommendedPlan(dummyList);
    setSelectedPLan(dummyList[0]);

    // Caching
    setCacheSelectedPlan(arr);

    // operating system
    setOsOptions(operating_system[dummyList?.[0]?.value?.os] || []);
  };

  const clearProductCart = () => {
    setRecommendedPlan(virtualMachinePlan);
    setSelectedData([]);
    setSelectedPLan(virtualMachinePlan[0]);
    setOsOptions(operating_system["Cloud Hosted"]);
    // Removing from local storage
    setCacheSelectedPlan([]);
    setCachePackage([]);
    setCacheOS([]);
    setCacheBillingCycle([]);
  };

  return (
    <PocketCalculator.Provider
      value={{
        EntireData,
        SetEntireData,
        quantityChangeFunction,
        header,
        SelectedData,
        // modal
        ShowModal,
        setShowModal,
        // ProductModal
        virtualMachinePlan,
        selectedPlan,
        setSelectedPLan,
        billingCycleOptions,
        billingCycle,
        setBillingCycle,
        recommendedPlan,
        setRecommendedPlan,
        calcRecommendedPlan,
        // Operating System Drop Down
        selectedOS,
        setSelectedOS,
        operating_system,
        // Billing Drop Down
        OsOptions,
        setOsOptions,
        BillingCycleData,
        setBillingCycleData,
        // caching
        cachePackage,
        setCachePackage,
        cacheOS,
        setCacheOS,
        cacheBillingCycle,
        setCacheBillingCycle,
        // clear cart
        clearProductCart,
        // request Id
        requestId,
      }}
    >
      {children}
    </PocketCalculator.Provider>
  );
};
